@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap");
:root {
  --float-from: 0px, 0px;
  --float-to: -30px, 20px;
}
* {
  margin: 0;
  outline: white;
}
body {
  overflow-x: hidden;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(26, 12, 71, 1) 30%,
    rgba(26, 12, 71, 1) 50%,
    rgba(26, 12, 71, 1) 70%,
    rgba(0, 0, 0, 1) 100%
  );
}

h1 {
  font-size: 72px;
  font-weight: 900;
}

h2 {
  font-size: 36px;
  font-weight: 900;
}

h3 {
  font-size: 16px;
  padding: 12px 24px;
}

h4 {
  font-size: 16px;
}

ul {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem;
}

li {
  position: relative;
  height: 24px;
  display: block;
  text-align: center;
  line-height: 2rem;
  color: white;
  list-style-type: none;
  padding: 12px 0;
  margin: 0 32px;
}
li::after {
  content: "";
  border-bottom: 1px solid white;
  width: 0%;
  height: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: width 0.4s;
}

li:hover {
  -webkit-filter: brightness(0.8);
  filter: brightness(0.8);
}
li:hover:after {
  width: 100%;
}

img {
  max-height: 80px;
  padding: 20px;
}

.app__container {
  display: flex;
  flex-direction: column;
  height: 98vh;
}

.content__container {
  color: white;
  font-size: 32px;
  height: 100%;
  display: grid;
  grid-template-columns: 66% 33%;
  grid-template-rows: 10% 90%;
  grid-template-areas:
    "header header"
    "main-content side-content";
  margin: 2rem 8rem;
  max-width: 100%;
  overflow: hidden;
}

#content__dots-canvas {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-filter: blur(2px);
  filter: blur(2px);
  z-index: -1;
  transition: transform 1s ease-in-out;
}

.navbar__container {
  margin: 0 48px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  font-family: "Roboto";
  font-size: 32px;
  font-weight: 900;
}

.maincontenthome__container {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.fade-in {
  -webkit-animation: fade-in 500ms forwards;
  animation: fade-in 500ms forwards;
}

.fade-out {
  -webkit-animation: fade-out 500ms forwards;
  animation: fade-out 500ms forwards;
}

.maincontenthome__greetings {
  font-size: 42px;
}

.maincontenthome__title {
  font-size: 84px;
}

.maincontenthome__keyword-experience {
  color: #2892d7;
  cursor: pointer;
}

.maincontenthome__keyword-contact {
  color: #2892d7;
  cursor: pointer;
}

.maincontentarea__container {
  margin: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  min-height: 600px;
  grid-area: main-content;
}

.maincontentexperience__floating-circle {
  position: absolute;
  width: 8px;
  height: 8px;
  background: transparent;
  border-radius: 50%;
  -webkit-filter: blur(2px);
  filter: blur(2px);
  z-index: -1;
}

.maincontentexperience__experience-cards-container {
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 100%;
}

.maincontentarea__container {
  font-size: 48px;
}

.maincontentcontact__toast {
  font-size: 24px;
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
}

.maincontentcontact__form {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.maincontentcontact__input {
  font-size: 16px;
  color: white;
  background: transparent;
  border: 1px solid white;
  padding: 15px;
}

.maincontentcontact__input::-webkit-input-placeholder {
  color: ghostwhite;
  opacity: 1; /* Firefox */
}

.maincontentcontact__input::-moz-placeholder {
  color: ghostwhite;
  opacity: 1; /* Firefox */
}

.maincontentcontact__input:-ms-input-placeholder {
  color: ghostwhite;
  opacity: 1; /* Firefox */
}

.maincontentcontact__input::-ms-input-placeholder {
  color: ghostwhite;
  opacity: 1; /* Firefox */
}

.maincontentcontact__input::placeholder {
  color: ghostwhite;
  opacity: 1; /* Firefox */
}

.maincontentcontact__input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: ghostwhite;
}

.maincontentcontact__input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: ghostwhite;
}

.maincontentcontact__input-body {
  min-height: 300px;
}

.maincontentcontact__button-submit {
  padding: 15px;
  background: #2892d7;
  color: white;
  border-radius: 15px;
  font-size: 24px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-self: end;
  display: inline-flex;
}

.maincontentcontact__button-submit-container {
  align-self: end;
  display: grid;
  place-items: center;
}

.maincontentcontact__spinner {
  color: white;
  align-self: end;
  position: absolute !important;
  top: 50%;
  right: 50%;
}

.maincontentcontact__label {
  font-size: 16px;
  color: ghostwhite;
}

.sidebararea__container {
  margin: 2rem;
  position: relative;
  color: ghostwhite;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  grid-area: side-content;
}

.sidebarexperience__technologies-container {
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: 100%;
  grid-template-rows: 100px 300px;
  grid-template-areas:
    "header"
    "icons";
}

.sidebarexperience__tools-container {
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: 100%;
  grid-template-rows: 300px;
  grid-template-areas: "icons";
}

.sidebarexperience__technology-icon-container,
.sidebarexperience__tools-icon-container {
  display: grid;
  place-items: center;
}

.sidebarexperience__tools-icons,
.sidebarexperience__technologies-icons {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: 50% 50%;
  place-items: center;
}

.sidebarexperience__technologies-title {
  grid-area: header;
}

.sidebarexperience__technologies-title-container,
.sidebararea__tools-title-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0 0;
}

.sidebarexperience__technologies-icons-container,
.sidebarexperience__tools-icons-container {
  color: white;
  width: 100%;
  height: 100%;
  border-radius: 0 0 10px 10px;
  display: flex;
  justify-content: center;
}

.sidebarexperience__tools-icon-name {
  text-align: center;
}

.sidebarexperience__logo-fade-out {
  -webkit-animation: 1s fade forwards;
  animation: 1s fade forwards;
  opacity: 0.2;
  -webkit-filter: grayscale(80%);
  filter: grayscale(80%);
}
.sidebarexperience__logo-fade-in {
  -webkit-animation: 1s fade-in forwards;
  animation: 1s fade-in forwards;
  opacity: 1;
}

.experience-card__container {
  display: flex;
  justify-content: center;
  min-width: 640px;
  max-width: 640px;
}

.experiencecard__company-duration {
  background: linear-gradient(
    90deg,
    rgba(88, 173, 159, 1) 0%,
    rgba(71, 206, 222, 1) 25%,
    rgba(20, 141, 230, 1) 50%,
    rgba(30, 118, 255, 1) 75%,
    rgba(173, 13, 190, 1) 100%
  );
}

.experiencecard__company-role {
  background: black;
}

.experiencecard__description {
  transition: scale 0.3s linear;
}

.experiencecard__company-logo {
  align-self: center;
  max-height: 140px;
  max-width: 300px;
  padding: 0;
}

.experiencecard__company-logo-container {
  background: rgb(0, 0, 0, 0.5);
  border-radius: 10px 10px 0 0;
  display: grid;
  height: 250px;
  place-items: center;
}
.experiencecard__description-container {
  border-radius: 0 0 10px 10px;
  font-size: 20px;
  height: 300px;
  line-height: 24px;
  padding: 24px;
  text-align: center;
  word-break: normal;
  color: white;
  background: rgb(0, 0, 0, 0.5);
}

/* to remove? */
.minimized {
  scale: 0;
}

.experiencecard__position-left {
  translate: -275px 0px;
  opacity: 0.2;
}
.experiencecard__position-right {
  translate: 275px 0px;
  opacity: 0.2;
}
.experiencecard__position-back {
  opacity: 0.2;
}
.experiencecard__position-front {
  position: absolute;
}

/* to remove? */

@-webkit-keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 1023px) {
  li {
    margin: 0 12px;
    font-size: 32px;
  }

  .content__container {
    grid-template-rows: 10% 50% 50%;
    grid-template-columns: 100%;
    grid-template-areas:
      "header"
      "main-content"
      "side-content";
  }
  .navbar__container {
    font-size: 24px;
  }

  .maincontentarea__container {
    margin: 0px;
  }
  .maincontenthome__title {
    font-size: 76px;
  }
  .maincontenthome__greetings {
    font-size: 42px;
  }
  .sidebararea__container {
    margin: 0;
  }
  .content__container {
    margin: 0px 12px;
  }
  .experience-card__container {
    min-width: 510px;
    max-width: 510px;
  }
  .sidebararea__container {
    display: none;
  }
  .maincontentcontact__input-body {
    min-height: 300px;
  }
  .maincontentcontact__button-submit {
    font-size: 24px;
    border: none;
  }
}

@media screen and (max-width: 767px) {
  li {
    margin: 0 12px;
    font-size: 24px;
  }
  .app__container {
    height: 98svh;
  }
  .experiencecard__description-container {
    font-size: 16px;
    line-height: 20px;
  }

  .content__container {
    grid-template-rows: 10% 50% 50%;
    grid-template-columns: 100%;
    grid-template-areas:
      "header"
      "main-content"
      "side-content";
  }
  .navbar__container {
    font-size: 16px;
  }

  .maincontentarea__container {
    margin: 0px;
  }
  .maincontenthome__title {
    font-size: 38px;
  }
  .maincontenthome__greetings {
    font-size: 32px;
  }
  .sidebararea__container {
    margin: 0;
  }
  .content__container {
    margin: 0px 12px;
  }
  .experience-card__container {
    min-width: 310px;
    max-width: 310px;
  }
  .sidebararea__container {
    display: none;
  }
  .maincontentcontact__input-body {
    min-height: 200px;
  }
  .maincontentcontact__button-submit {
    font-size: 16px;
    border: none;
  }
  .experiencecard__company-logo-container {
    height: 150px;
  }
}
